@import url('https://rsms.me/inter/inter.css');

* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

#root {
  position: relative;
  padding: 2.5em;
}

body {
  background: 191919;
  font-family: inter;
}

.container {
  position: relative;
  width: 100%;
  height: 100%;
}

.text {
  line-height: 1em;
  text-align: center;
  font-size: 8em;
  word-break: break-word;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
